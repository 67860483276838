import React from "react";
import { Thunderstorm } from "./thunderstorm";
import { Drizzle } from "./drizzle";
import { Rain } from "./rain";
import { Snow } from "./snow";
import { Atmosphere } from "./atmosphere";
import { Clear } from "./clear";
import { Clouds } from "./clouds";

export const Weather = attrs => {
	if (attrs.code < 300) {
		return <Thunderstorm {...attrs} />; //Group 2xx: Thunderstorm
	} else if (attrs.code < 400) {
		return <Drizzle {...attrs} />; // Group 3xx: Drizzle
	} else if (attrs.code < 600) {
		return <Rain {...attrs} />; // Group 5xx: Rain
	} else if (attrs.code < 700) {
		return <Snow {...attrs} />; // Group 6xx: Snow
	} else if (attrs.code < 800) {
		return <Atmosphere {...attrs} />; // Group 7xx: Atmosphere
	} else if (attrs.code === 800) {
		return <Clear {...attrs} />; // Group 800: Clear
	} else if (attrs.code < 900) {
		return <Clouds {...attrs} />; // Group 80x: Clouds
	} else {
		return null;
	}
};
