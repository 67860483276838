import React, { Component } from "react";
import ReactDOM from "react-dom";
import styled, { createGlobalStyle } from "styled-components";
import { iconPrimaryColor, iconSecondaryColor } from "../../colors.theme";
import { DuoToneGradient } from "./duotone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faUmbrella,
	faTintSlash,
	faCloudRain,
	faCloudShowers,
	faCloudShowersHeavy,
	faCloudSleet,
	faCloudMoonRain,
	faCloudSunRain,
	faCloudDownload
} from "@fortawesome/pro-duotone-svg-icons";

const conditionIcon = (conditionCode, cloudPercentage, isNight) => {
	if (conditionCode < 500 || conditionCode > 599) return faCloudDownload;

	let iconSlug = faCloudRain;
	switch (conditionCode) {
		case 500:
		case 501:
			iconSlug = faCloudShowers;
			break;
		case 502:
		case 503:
		case 504:
		case 522:
			iconSlug = faCloudShowersHeavy;
			break;
		case 511:
			iconSlug = faCloudSleet;
			break;
		case 520:
		case 521:
			iconSlug = faCloudShowers;
			break;
		default:
			break;
	}
	if (cloudPercentage < 50) {
		iconSlug = isNight ? faCloudMoonRain : faCloudSunRain;
	}

	return iconSlug;
};

const DuoTone = createGlobalStyle`
	#gradient {
		--color-stop-1: ${iconPrimaryColor};
		--color-stop-2: ${iconSecondaryColor};
	}
`;

class DuoToneGradientPortal extends Component {
	// TODO render only once
	render() {
		return ReactDOM.createPortal(
			<DuoToneGradient id='gradient' />,
			document.getElementById("gradients")
		);
	}
}

export const RainIcon = styled(
	({ code, cloudPercentage, isNight, variant, ...rest }) => (
		<FontAwesomeIcon {...rest} />
	)
).attrs(({ code, cloudPercentage, isNight }) => ({
	icon: conditionIcon(code, cloudPercentage, isNight),
	variant: cloudPercentage < 50 ? "duotone" : "rain"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;

export const Rain = props => (
	<React.Fragment>
		{props.cloudPercentage < 50 ? (
			<React.Fragment>
				<DuoToneGradientPortal />
				<DuoTone variant='sun-rain' />
			</React.Fragment>
		) : null}
		<RainIcon {...props} />
	</React.Fragment>
);

export const RainChance = styled(({ chance, variant, ...rest }) => (
	<FontAwesomeIcon {...rest} />
)).attrs(({ chance }) => ({
	icon: chance ? faUmbrella : faTintSlash,
	variant: "rain"
}))`
	--fa-primary-opacity: ${({ chance }) => (chance ? 1 : 0.5)};
	--fa-secondary-opacity: ${({ chance }) => (chance ? 1 : 0.6)};
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;
