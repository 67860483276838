import React, { useContext, useState } from "react";
import styled from "styled-components";
import AppContext from "../appContext";
import cities from "../cities";

const PlaceNameContainer = styled.div`
	font-size: 4rem;

	text-align: left;
`;

const PlaceNameDropdown = styled.ul`
	position: absolute;
	z-index: 1;
	margin: 0;
	padding: 0;
	list-style-type: none;
	max-height: calc(100vh - 6rem);
	overflow-x: none;
	overflow-y: auto;
	background-color: white;
	box-shadow: 0px 8px 8px 0px #eee;
	border-radius: 8px;
`;

const PlaceNameDropdownItem = styled.li`
	font-size: 2rem;
`;

const PlaceNameButton = styled.button`
	background-color: transparent;
	border-color: transparent;
	color: inherit;
`;

const PlaceNameOption = ({ city }) => {
	const [, dispatch] = useContext(AppContext);

	const handleCityUpdate = ({ target }) => {
		dispatch({ type: "UPDATE_CITY", city: target.value });
	};

	return (
		<PlaceNameDropdownItem>
			<PlaceNameButton value={city} onClick={handleCityUpdate}>
				{city}
			</PlaceNameButton>
		</PlaceNameDropdownItem>
	);
};

export const PlaceName = () => {
	const [{ city }] = useContext(AppContext);
	const [visible, setVisibility] = useState(false);

	const handleCityDropdown = evt => {
		setVisibility(!visible);
	};

	return (
		<PlaceNameContainer onClick={handleCityDropdown}>
			{city || `Unknown`}
			{visible ? (
				<PlaceNameDropdown>
					{cities.map((city, index) => (
						<PlaceNameOption city={city} key={index} />
					))}
				</PlaceNameDropdown>
			) : null}
		</PlaceNameContainer>
	);
};
