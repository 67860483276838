import React from "react";
import styled from "styled-components";
import { useFetchRainChance } from "./hooks/fetchRainChance";
import { RainChance } from "./components/icons/rain";
import { RainForecastGraph } from "./components/graphs/rain";

const IconContainer = styled.div.attrs({
	className: "fa-6x"
})`
	text-align: center;
	& svg {
		margin-top: 1rem;
	}
`;

function RainApp({ lat, lon }) {
	const { rainChance, loading, error } = useFetchRainChance({ lat, lon });

	if (loading) return <div>Loading...</div>;
	if (error) return <div>{error}</div>;

	let chanceOfRain = false;
	for (const chance of rainChance) {
		if (Number(chance.intensity) > 0) {
			chanceOfRain = true;
		}
	}

	return (
		<IconContainer>
			<RainChance chance={chanceOfRain} />
			<RainForecastGraph data={rainChance} />
		</IconContainer>
	);
}

export default RainApp;
