import React, { useEffect, useRef } from "react";
import { transparentize } from "polished";
import { colors } from "../colors.theme";

const getPixelRatio = context => {
	var backingStore =
		context.backingStorePixelRatio ||
		context.webkitBackingStorePixelRatio ||
		context.mozBackingStorePixelRatio ||
		context.msBackingStorePixelRatio ||
		context.oBackingStorePixelRatio ||
		context.backingStorePixelRatio ||
		1;

	return (window.devicePixelRatio || 1) / backingStore;
};

const ProgressRing = ({
	progress = 0,
	fullRingAt = 100,
	size = [24, 24],
	thickness = 5,
	color = colors.hitGrey,
	className
}) => {
	let ring = useRef();
	const perc = Math.round(progress * (100 / fullRingAt));

	useEffect(() => {
		let canvas = ring.current;
		let context = canvas.getContext("2d");

		let ratio = getPixelRatio(context);
		const width = size[0] || size,
			height = size[1] || size[0] || size,
			line = thickness * ratio,
			angle = (2 * Math.PI) / fullRingAt,
			start = 1.5 * Math.PI;

		canvas.width = width * ratio;
		canvas.height = height * ratio;
		canvas.style.width = `${width}px`;
		canvas.style.height = `${height}px`;

		let requestId;
		const render = () => {
			context.clearRect(0, 0, canvas.width, canvas.height);
			context.beginPath();
			context.strokeStyle = transparentize(0.6, color);
			context.lineWidth = line;
			context.arc(
				canvas.width / 2,
				canvas.height / 2,
				canvas.width / 2 - line / 2,
				start,
				Math.PI - 1.5 * Math.PI
			);
			context.stroke();
			context.beginPath();
			context.lineCap = "round";
			context.strokeStyle = transparentize(0.2, color);
			context.arc(
				canvas.width / 2,
				canvas.height / 2,
				canvas.width / 2 - line / 2,
				start,
				start + progress * angle
			);
			context.stroke();

			requestId = requestAnimationFrame(render);
		};

		render();

		return () => {
			cancelAnimationFrame(requestId);
		};
	});

	return <canvas className={className} ref={ring}>{`${perc}%`}</canvas>;
};

export const LoadingRing = ({
	size = [24, 24],
	thickness = 5,
	color = colors.hitGrey
}) => {
	let ring = useRef();

	useEffect(() => {
		let canvas = ring.current;
		let context = canvas.getContext("2d");

		let ratio = getPixelRatio(context);
		const width = size[0] || size,
			height = size[1] || size[0] || size,
			line = thickness * ratio,
			angle = (2 * Math.PI) / 100,
			start = 1.5 * Math.PI;

		canvas.width = width * ratio;
		canvas.height = height * ratio;
		canvas.style.width = `${width}px`;
		canvas.style.height = `${height}px`;

		let requestId,
			i = 0;
		const render = () => {
			context.clearRect(0, 0, canvas.width, canvas.height);
			context.beginPath();
			context.strokeStyle = transparentize(0.6, color);
			context.lineWidth = line;
			context.arc(
				canvas.width / 2,
				canvas.height / 2,
				canvas.width / 2 - line / 2,
				start,
				Math.PI - 1.5 * Math.PI
			);
			context.stroke();
			context.beginPath();
			context.lineCap = "round";
			context.strokeStyle = transparentize(0.2, color);
			context.arc(
				canvas.width / 2,
				canvas.height / 2,
				canvas.width / 2 - line / 2,
				start + (i + 90) * angle,
				start + i * angle
			);
			context.stroke();
			i++;
			if (i === 100) i = 0;

			requestId = requestAnimationFrame(render);
		};

		render();

		return () => {
			cancelAnimationFrame(requestId);
		};
	});

	return <canvas ref={ring}>{`loading`}</canvas>;
};

export default ProgressRing;
