import { useEffect, useState } from "react";
import rainChanceModel from "./fetchRainChance.model";

export const useFetchRainChance = ({ lat, lon }) => {
	const [rainChance, setRainChance] = useState(rainChanceModel);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;

		setLoading(true);
		setError(null);

		fetch(
			`https://gpsgadget.buienradar.nl/data/raintext?lat=${lat}&lon=${lon}`,
			{ signal: signal }
		)
			.then(res => res.text())
			.then(text => {
				setLoading(false);
				if (text) {
					const dataArray = text.split(/\r?\n/);
					const rainForecast = dataArray
						.filter(line => line.length > 0)
						.map(line => {
							const data = line.split("|");
							return { time: data[1], intensity: data[0] };
						});
					setRainChance(rainForecast);
				} else {
					// No data...
					setError(true);

					setRainChance([]);
				}
			})
			.catch(err => {
				// No connection...
				setError(err);
				setLoading(false);
			});

		return function cleanup() {
			abortController.abort();
		};
	}, [lat, lon]);

	//console.log(rainChance, loading, error);
	return { rainChance, loading, error };
};
