import React, { useState } from "react";
import styled from "styled-components";
import { useInterval } from "../hooks/interval";

const TimeContainer = styled.div`
	font-size: 1.5rem;
	position: absolute;
`;

export const Time = () => {
	let [clock, setClock] = useState(new Date());

	useInterval(() => {
		setClock(new Date());
	}, 1000);

	return <TimeContainer>{clock.toLocaleTimeString("nl-NL")}</TimeContainer>;
};
