import { useEffect, useState } from "react";
import weatherForecastModel from "./fetchWeatherForecast.model";

export const useFetchWeatherForecast = ({ city, timestamp }) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(null);
	const [weather, setWeather] = useState(weatherForecastModel);

	useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;

		setLoading(true);
		setError(null);

		fetch(
			`https://api.openweathermap.org/data/2.5/forecast?q=${city},nl&lang=nl&units=metric&cnt=24&appid=2110f53ef617108d8b246d2cd0e2e22e`,
			{ signal: signal }
		)
			.then(res => res.json())
			.then(json => {
				if (json) {
					setWeather(json);
				} else {
					setWeather([]);
				}
				setLoading(false);
			})
			.catch(err => {
				setError(err);
				setLoading(false);
			});

		return function cleanup() {
			abortController.abort();
		};
	}, [city, timestamp]);

	//console.log("useFetchWeatherForecast", weather, loading, error);
	return { weather, loading, error };
};
