import React from "react";
import styled from "styled-components";
import { iconPrimaryColor, iconSecondaryColor } from "../../colors.theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMoonCloud,
	faSunCloud,
	faCloudMoon,
	faCloudSun,
	faCloudsMoon,
	faCloudsSun,
	faClouds,
	faCloudDownload
} from "@fortawesome/pro-duotone-svg-icons";

const conditionIcon = (conditionCode, cloudPercentage, isNight) => {
	if (conditionCode < 801 || conditionCode > 899) return faCloudDownload;

	let iconSlug = faClouds;
	if (cloudPercentage < 25) {
		iconSlug = isNight ? faMoonCloud : faSunCloud;
	} else if (cloudPercentage < 50) {
		iconSlug = isNight ? faCloudMoon : faCloudSun;
	} else if (cloudPercentage < 85) {
		iconSlug = isNight ? faCloudsMoon : faCloudsSun;
	} else {
		iconSlug = faClouds;
	}

	return iconSlug;
};

export const Clouds = styled(
	({ code, cloudPercentage, isNight, variant, ...rest }) => (
		<FontAwesomeIcon {...rest} />
	)
).attrs(({ code, cloudPercentage, isNight }) => ({
	icon: conditionIcon(code, cloudPercentage, isNight),
	variant: cloudPercentage > 84 ? "clouds" : "default"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;
