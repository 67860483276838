import React from "react";
import styled from "styled-components";
import { iconPrimaryColor, iconSecondaryColor } from "../../colors.theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faMoonStars,
	faSun,
	faCloudDownload
} from "@fortawesome/pro-duotone-svg-icons";

const conditionIcon = (conditionCode, _cloudPercentage, isNight) => {
	if (conditionCode < 800 || conditionCode > 800) return faCloudDownload;

	let iconSlug = isNight ? faMoonStars : faSun;

	return iconSlug;
};

export const Clear = styled(
	({ code, cloudPercentage, isNight, variant, ...rest }) => (
		<FontAwesomeIcon {...rest} />
	)
).attrs(({ code, cloudPercentage, isNight }) => ({
	icon: conditionIcon(code, cloudPercentage, isNight),
	variant: "sun"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;
