import theme from "styled-theming";
import { darken, lighten } from "polished";

const seagull = "#87ceeb";
const wildRice = "#ebe087";
const tacao = "#eba487";
const sulu = "#9ceb87";
const geyser = "#d4dfe2";
const hitGrey = "#a1b2b5";

export const colors = {
	seagull,
	wildRice,
	tacao,
	sulu,
	geyser,
	hitGrey
};

export const iconPrimaryColor = theme.variants("mode", "variant", {
	default: { light: darken(0.2, seagull), dark: seagull },
	sun: { light: darken(0.2, wildRice), dark: wildRice },
	haze: { light: seagull, dark: lighten(0.2, seagull) },
	fog: { light: darken(0.2, seagull), dark: seagull },
	clouds: { light: seagull, dark: lighten(0.2, seagull) },
	rain: { light: darken(0.2, seagull), dark: seagull },
	"sun-rain": { light: darken(0.2, wildRice), dark: wildRice },
	snow: { light: darken(0.2, seagull), dark: seagull },
	fire: { light: darken(0.2, hitGrey), dark: hitGrey },
	warning: { light: seagull, dark: lighten(0.2, seagull) },
	duotone: { light: darken(0.2, seagull), dark: seagull },
	compass: { light: darken(0.2, tacao), dark: tacao },
	"rain-graph": { light: darken(0.2, seagull), dark: seagull },
	spinner: { light: hitGrey, dark: hitGrey }
});

export const iconSecondaryColor = theme.variants("mode", "variant", {
	default: { light: darken(0.2, wildRice), dark: wildRice },
	sun: { light: wildRice, dark: lighten(0.2, wildRice) },
	haze: { light: darken(0.2, wildRice), dark: wildRice },
	fog: { light: seagull, dark: lighten(0.2, seagull) },
	clouds: { light: darken(0.2, seagull), dark: seagull },
	rain: { light: seagull, dark: lighten(0.2, seagull) },
	"sun-rain": { light: seagull, dark: lighten(0.2, seagull) },
	snow: { light: lighten(0.1, seagull), dark: lighten(0.3, seagull) },
	fire: { light: darken(0.2, tacao), dark: tacao },
	warning: { light: darken(0.1, tacao), dark: tacao },
	duotone: {
		light: `url(#gradient) ${seagull}`,
		dark: `url(#gradient) ${lighten(0.2, seagull)}`
	},
	compass: { light: geyser, dark: geyser },
	"rain-graph": { light: hitGrey, dark: hitGrey },
	spinner: { light: hitGrey, dark: hitGrey }
});

export const iconTextColor = theme.variants("mode", "variant", {
	"rain-graph": { light: hitGrey, dark: hitGrey }
});
