import React from "react";
import styled from "styled-components";
import { iconPrimaryColor, iconSecondaryColor } from "../../colors.theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faThunderstorm,
	faThunderstormMoon,
	faThunderstormSun,
	faCloudDownload
} from "@fortawesome/pro-duotone-svg-icons";

const conditionIcon = (conditionCode, cloudPercentage, isNight) => {
	if (conditionCode < 200 || conditionCode > 299) return faCloudDownload;

	let iconSlug = faThunderstorm; 
	if (cloudPercentage < 50) {
		iconSlug = isNight ? faThunderstormMoon : faThunderstormSun;
	}

	return iconSlug;
};

export const Thunderstorm = styled(
	({ code, cloudPercentage, isNight, variant, ...rest }) => (
		<FontAwesomeIcon {...rest} />
	)
).attrs(({ code, cloudPercentage, isNight }) => ({
	icon: conditionIcon(code, cloudPercentage, isNight),
	variant: "default"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;
