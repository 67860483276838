import React from "react";
import styled from "styled-components";
import { iconPrimaryColor, iconSecondaryColor } from "../../colors.theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faThermometerEmpty,
	faTemperatureFrigid,
	faThermometerQuarter,
	faThermometerHalf,
	faThermometerThreeQuarters,
	faThermometerFull,
	faTemperatureHot
} from "@fortawesome/pro-duotone-svg-icons";

const conditionIcon = temp => {
	let iconSlug = faThermometerEmpty;

	if (temp < 0) {
		iconSlug = faTemperatureFrigid;
	} else if (temp < 10) {
		iconSlug = faThermometerQuarter;
	} else if (temp < 15) {
		iconSlug = faThermometerHalf;
	} else if (temp < 25) {
		iconSlug = faThermometerThreeQuarters;
	} else if (temp < 30) {
		iconSlug = faThermometerFull;
	} else if (temp >= 30) {
		iconSlug = faTemperatureHot;
	}

	return iconSlug;
};

export const Temp = styled(({ temp, variant, ...rest }) => (
	<FontAwesomeIcon {...rest} />
)).attrs(({ temp }) => ({
	icon: conditionIcon(temp),
	variant: "compass"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;
