export const getValueByPath = (obj, path) => {
	return path.split(".").reduce((o, i) => o[i], obj);
};
export const getMinKey = (data, key) => {
	return data.reduce(
		(min, p) => (getValueByPath(p, key) < min ? getValueByPath(p, key) : min),
		getValueByPath(data[0], key)
	);
};
export const getMaxKey = (data, key) => {
	return data.reduce(
		(max, p) => (getValueByPath(p, key) > max ? getValueByPath(p, key) : max),
		getValueByPath(data[0], key)
	);
};
export const isNight = (time, sunset, sunrise) =>
	time > sunset || time < sunrise;
