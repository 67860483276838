export const degToCompass = deg => {
	const degr = Math.floor(deg / 22.5 + 0.5);
	const directions = [
		"N",
		"NNO",
		"NO",
		"ONO",
		"O",
		"OZO",
		"ZO",
		"ZZO",
		"Z",
		"ZZW",
		"ZW",
		"WZW",
		"W",
		"WNW",
		"NW",
		"NNW"
	];
	return directions[degr % 16];
};

export const msToKmh = speed => {
	return `${(speed * 3.6).toFixed(0)}km/u`;
};
