//import React from 'react';
import styled from "styled-components";

export const GridContainer = styled.div`
	padding: 0.5rem 1rem;
	display: grid;
	grid-template-columns: 1.5fr 1fr 1.5fr;
	grid-template-rows: 0.25fr 1fr 1.5fr;
	grid-template-areas: "city city icon" "rain wind icon" "graph graph graph";
	justify-items: center;
	align-items: center;
	height: calc(100vh - 1rem);
`;

export const GridArea = styled.div`
	grid-area: ${({ area }) => area};
	justify-self: ${({ justify }) => justify};
	align-self: ${({ align }) => align};
`;
