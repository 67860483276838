import React from "react";
import styled from "styled-components";

import { WeatherConsumer } from "../weatherContext";
import { Temp } from "./icons/temp";

const TempIconContainer = styled.div`
	font-size: 5rem;
	text-align: center;
`;

const Label = styled.span`
	font-size: 3rem;
	padding: 1rem;
	display: inline-block;
	vertical-align: top;
`;

export const TempIcon = () => (
	<WeatherConsumer>
		{({ weather: { main }, error }) => (
			<TempIconContainer>
				<Temp temp={main.temp} />
				<Label>
					{error ? <>{`?`}</> : Math.round(main.temp)}
					&deg;C
				</Label>
			</TempIconContainer>
		)}
	</WeatherConsumer>
);
