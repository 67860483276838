import React from "react";
import styled from "styled-components";
import { WeatherConsumer } from "../weatherContext";
import { Weather } from "./icons/weather";

const IconContainer = styled.div.attrs({
	className: "fa-10x"
})`
	text-align: center;
`;

const DescriptionContainer = styled.div`
	text-align: center;
	font-size: 3rem;
	text-transform: capitalize;
`;

export const WeatherIcon = () => (
	<WeatherConsumer>
		{({ weather: { weather, clouds, dt, sys }, loading }) => {
			if (!weather) return null;

			const isNight = dt > sys.sunset || dt < sys.sunrise;

			return weather.map(({ id, description }, index) => (
				<React.Fragment key={index}>
					<IconContainer>
						<Weather
							code={id}
							cloudPercentage={clouds.all}
							isNight={isNight}
						/>
					</IconContainer>
					<DescriptionContainer>{description}</DescriptionContainer>
				</React.Fragment>
			));
		}}
	</WeatherConsumer>
);
