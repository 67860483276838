import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faFog,
	faSmoke,
	faSunHaze,
	faSunDust,
	faFireSmoke,
	faWindWarning,
	faTornado,
	faCloudDownload
} from "@fortawesome/pro-duotone-svg-icons";
import { iconPrimaryColor, iconSecondaryColor } from "../../colors.theme";

const conditionIcon = (conditionCode, _cloudPercentage, _isNight) => {
	if (conditionCode < 700 || conditionCode > 799) return faCloudDownload;

	let iconSlug = faFog;
	let iconVariant = "fog";
	switch (conditionCode) {
		case 701:
		case 741:
			iconSlug = faFog;
			break;
		case 711:
			iconSlug = faSmoke;
			iconVariant = "clouds";
			break;
		case 721:
		case 731:
			iconSlug = faSunHaze;
			iconVariant = "haze";
			break;
		case 751:
		case 761:
			iconSlug = faSunDust;
			iconVariant = "default";
			break;
		case 762:
			iconSlug = faFireSmoke;
			iconVariant = "fire";
			break;
		case 771:
			iconSlug = faWindWarning;
			iconVariant = "warning";
			break;
		case 781:
			iconSlug = faTornado;
			break;
		default:
			break;
	}

	return { iconSlug, iconVariant };
};

export const Atmosphere = styled(
	({ code, cloudPercentage, isNight, variant, ...rest }) => (
		<FontAwesomeIcon {...rest} />
	)
).attrs(({ code, cloudPercentage, isNight }) => ({
	icon: conditionIcon(code, cloudPercentage, isNight).iconSlug,
	variant: conditionIcon(code).iconVariant
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;
