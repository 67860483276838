export default [
	"Amsterdam",
	"Rotterdam",
	"Den Haag",
	"Utrecht",
	"Eindhoven",
	"Groningen",
	"Breda",
	"Arnhem",
	"Apeldoorn",
	"Enschede",
	"'s-Hertogenbosch",
	"Zwolle",
	"Leeuwarden",
	"Maastricht",
	"Alkmaar",
	"Emmen",
	"Venlo",
	"Den Helder",
	"Middelburg",
	"Assen",
	"Lelystad",
	"Lemmer"
].sort(function(a, b) {
	return a.localeCompare(b, "nl", { ignorePunctuation: true });
});
