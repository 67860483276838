import React from "react";
import styled, { createGlobalStyle } from "styled-components";
import {
	iconPrimaryColor,
	iconSecondaryColor,
	iconTextColor
} from "../../colors.theme";

const GraphStyles = createGlobalStyle`
	.svg--graph {
		display: block;
		margin: 0;
		padding: 0;
		width: 100%;
	}
	.svg--graph .graph-primary {
		fill: var(--graph-primary-color,currentColor);
		stroke: var(--graph-primary-color,currentColor);
		opacity: 1;
		opacity: var(--graph-primary-opacity,1);
	}
	.svg--graph .graph-secondary {
		fill: var(--graph-secondary-color,currentColor);
		stroke: var(--graph-secondary-color,currentColor);
		opacity: 1;
		opacity: var(--graph-secondary-opacity,1);
	}
	.svg--graph .graph-text {
		font-size: 2rem;
		text-align: center;
		fill: var(--graph-text-color);
	}
`;

const RainForecastSVG = ({ data, className }) => {
	if (data.length === 0) return null;

	const step = 40;
	const pathPoints = data.map(
		({ intensity }, index) =>
			`S ${index * step + step / 2},${255 - Number(intensity)} ${index * step +
				step},${255 - Number(intensity)}`
	);

	const timeBars = data.map(({ time }, index) =>
		time.split(":")[1] % 15 === 0
			? `M ${index * step + step},0 L ${index * step + step},255`
			: ``
	);

	return (
		<React.Fragment>
			<GraphStyles />
			<svg
				className={`svg--graph ${className}`}
				viewBox={`0 0 ${data.length * step} 287`}
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					className="graph-secondary"
					fill="none"
					opacity="--graph-secondary-opacity"
					stroke="--graph-secondary-color"
					d={`M 0,0 L 0,255
						M 0,0 L 960,0
						${timeBars.join(" ")}
						M 960,0 L 960,255`}
				/>
				<path
					className="graph-primary"
					fill="--graph-primary-color"
					opacity="--graph-primary-opacity"
					stroke="none"
					d={`M 0,255
						L 0,${255 - Number(data[0].intensity)}
						${pathPoints.join(" ")}
						L 960,255
						Z`}
				/>
				{data.map(({ time }, index) =>
					time.split(":")[1] % 15 === 0 ? (
						<text className="graph-text" x={index * step} y="287" key={index}>
							{time}
						</text>
					) : null
				)}
			</svg>
		</React.Fragment>
	);
};

export const RainForecastGraph = styled(RainForecastSVG).attrs(() => ({
	variant: "rain-graph"
}))`
	--graph-primary-color: ${iconPrimaryColor};
	--graph-secondary-color: ${iconSecondaryColor};
	--graph-text-color: ${iconTextColor};
`;
