import React, { useState, useContext } from "react";
import styled from "styled-components";
import AppContext from "../appContext";
import { useInterval } from "../hooks/interval";
//import { iconPrimaryColor, iconSecondaryColor } from "../colors.theme";
import ProgressRingCanvas from "./progress";

const TimerContainer = styled.div`
	position: absolute;
	width: 24px;
	height: 24px;
	top: 0.2rem;
	right: 0.2rem;
`;

const ProgressRing = ({ className = "" }) => {
	let [progress, setProgress] = useState(0);
	const [state, dispatch] = useContext(AppContext);
	const refreshAt = 1000,
		refreshTime = 120; // in sec

	// const radius = 12;
	// const stroke = 3;
	// const circumference = (radius - stroke * 2) * 2 * Math.PI;

	const handleRefresh = () => {
		dispatch({ type: "RELOAD_ENDPOINTS" });
	};

	useInterval(() => {
		setProgress(progress++);
		if (progress > refreshAt) {
			setProgress(1);
			handleRefresh();
		}
	}, refreshTime);

	return (
		<TimerContainer
			title={`Last updated on ${state.timestamp.toLocaleTimeString("nl-NL")}`}
		>
			{/*<svg
				className={`svg--icon ${className}`}
				viewBox={`0 0 ${radius * 2} ${radius * 2}`}
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					className="icon-secondary"
					fill="transparent"
					opacity="var(--graph-secondary-opacity)"
					stroke="var(--graph-secondary-color)"
					strokeWidth="3px"
					r={radius - stroke * 2}
					cx={radius}
					cy={radius}
				/>
				<circle
					className="icon-primary"
					fill="transparent"
					opacity="var(--graph-primary-opacity)"
					stroke="var(--graph-primary-color)"
					strokeWidth="3px"
					stroke-linecap="round"
					transform={`rotate(-90 ${radius} ${radius})`}
					strokeDasharray={`${circumference} ${circumference}`}
					strokeDashoffset={circumference - (progress / 100) * circumference}
					r={radius - stroke * 2}
					cx={radius}
					cy={radius}
				/>
			</svg>*/}
			<ProgressRingCanvas
				className={className}
				progress={progress}
				fullRingAt={refreshAt}
			/>
		</TimerContainer>
	);
};

// export const RefreshTimer = styled(ProgressRing).attrs(() => ({
// 	variant: "spinner"
// }))`
// 	--graph-primary-opacity: 1;
// 	--graph-primary-color: ${iconPrimaryColor};
// 	--graph-secondary-opacity: 0.5;
// 	--graph-secondary-color: ${iconSecondaryColor};
// `;

export const RefreshTimer = ProgressRing;
