import React from "react";
import styled from "styled-components";
import { WindDirection, WindSpeed, Wind } from "./icons/wind";
import { degToCompass, msToKmh } from "../helpers/transformers";

import { WeatherConsumer } from "../weatherContext";

const WindIconContainer = styled.div`
	text-align: center;
	display: inline-grid;
	grid-template-columns: 1.2fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-template-areas: "icon direction" "icon speed";
	align-items: center;
`;

const WindIconArea = styled.div`
	grid-area: icon;
	font-size: 5rem;
	padding: 1rem;
	text-align: right;
`;
const WindDirectionArea = styled.div`
	grid-area: direction;
	font-size: 3rem;
`;
const WindSpeedArea = styled.div`
	grid-area: speed;
	font-size: 3rem;
`;

const Label = styled.div`
	font-size: 1.2rem;
`;

export const WindIcon = () => (
	<WeatherConsumer>
		{({ weather: { wind }, loading }) => (
			<WindIconContainer>
				<WindIconArea>
					<Wind speed={wind.speed} />
				</WindIconArea>
				<WindDirectionArea>
					<WindDirection deg={wind.deg} />
					<Label>{degToCompass(wind.deg)}</Label>
				</WindDirectionArea>
				<WindSpeedArea>
					<WindSpeed />
					<Label>{msToKmh(wind.speed)}</Label>
				</WindSpeedArea>
			</WindIconContainer>
		)}
	</WeatherConsumer>
);
