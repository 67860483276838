import React from "react";
import styled from "styled-components";
import {
	VictoryChart,
	VictoryLine,
	VictoryBar,
	VictoryScatter,
	VictoryAxis
} from "victory";
import { WeatherForecastConsumer } from "../weatherForecastContext";
import { Weather } from "./icons/weather";
import { colors } from "../colors.theme";
import VictoryTheme from "../victory.theme";
import { getMinKey, getMaxKey } from "../helpers/lookup";
import { LoadingRing } from "./progress";
// import { degToCompass } from "../helpers/transformers";

const LoadingContainer = styled.div`
	display: flex;
	flex-wrap: nowrap;
	justify-content: center;
`;

const ChartsContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-template-rows: 2rem 1fr;
	grid-template-areas: "icons" "charts";
	justify-items: stretch;
	align-items: center;

	& .VictoryContainer {
		grid-area: charts;
	}
`;

const IconsContainer = styled.ul`
	grid-area: icons;
	list-style: none;
	padding: 1.5rem calc(5% - 15px) 0;
	margin: 0;
	display: flex;
	flex-wrap: nowrap;
`;

const IconItem = styled.li`
	flex: 1 1 100%;
	text-align: center;
	font-size: 1.5rem;
`;

const ArrowPoint = ({ x, y, datum }) => {
	return (
		<g
			transform={`translate(${x} ${y}) scale(0.075) rotate(${datum.wind.deg} 0 0)`}
		>
			<i
				data-fa-transform={`rotate--45 up-6 left-32`}
				className="fas fa-location-arrow"
				style={{ color: colors.tacao }}
			>
				{datum.wind.speed}
			</i>
		</g>
	);
};
export const Forecast = () => {
	return (
		<WeatherForecastConsumer>
			{({ weather: { list, cnt }, loading }) =>
				loading ? (
					<LoadingContainer>
						<LoadingRing size={[42, 42]} thickness={7} />
					</LoadingContainer>
				) : list && cnt > 0 ? (
					<ChartsContainer>
						<IconsContainer>
							{list.map(({ weather, clouds, sys }, index) => (
								<IconItem key={index}>
									<Weather
										code={weather[0].id}
										cloudPercentage={clouds.all}
										isNight={sys.pod === "n" ? true : false}
									/>
								</IconItem>
							))}
						</IconsContainer>
						<VictoryChart
							theme={VictoryTheme.meteo}
							height={125}
							width={500}
							padding={{ top: 10, bottom: 25, left: 30, right: 30 }}
						>
							<VictoryBar
								data={list}
								x={datum => new Date(datum.dt_txt)}
								y={({ rain = { "3h": 0 }, snow = { "3h": 0 } }) =>
									rain["3h"] + snow["3h"] || 0
								}
								domain={{ y: [0, 7.5] }}
								barRatio={1}
								style={{
									data: {
										fill: colors.seagull
									}
								}}
							/>
							<VictoryAxis
								dependentAxis
								orientation="right"
								tickFormat={t => `${t}mm`}
								style={{
									axis: { stroke: "transparent" },
									tickLabels: { fontSize: 5, fill: colors.seagull }
								}}
							/>
						</VictoryChart>
						<VictoryChart
							theme={VictoryTheme.meteo}
							height={125}
							width={500}
							padding={{ top: 10, bottom: 25, left: 30, right: 30 }}
							minDomain={
								getMinKey(list, "main.temp") > 0
									? { y: 0 }
									: getMinKey(list, "main.temp") - 5
							}
							maxDomain={
								getMaxKey(list, "main.temp") < 20
									? { y: 20 }
									: getMaxKey(list, "main.temp") + 5
							}
						>
							<VictoryLine
								data={list}
								x={datum => new Date(datum.dt_txt)}
								y="main.temp"
								interpolation="catmullRom"
								scale={{ x: "time" }}
								style={{
									data: {
										stroke: colors.wildRice,
										strokeWidth: 3
									}
								}}
							/>
							<VictoryAxis
								dependentAxis
								orientation="left"
								tickFormat={t => `${t}°C`}
								style={{
									axis: { stroke: "transparent" },
									tickLabels: { fontSize: 7, fill: colors.wildRice }
								}}
							/>
							<VictoryAxis
								tickCount={12}
								tickFormat={t =>
									`${new Date(t).toLocaleTimeString("nl-NL", {
										hour: "2-digit"
										//minute: "2-digit"
									})}:00`
								}
								orientation="top"
								thickValues={list.map(({ dt_txt }) => new Date(dt_txt))}
								style={{
									axis: { stroke: "transparent" },
									tickLabels: { fontSize: 6 }
								}}
							/>
							<VictoryAxis
								tickCount={3}
								tickFormat={t =>
									`${new Date(t).toLocaleDateString("nl-NL", {
										weekday: "long",
										day: "numeric",
										month: "long"
									})}`
								}
								thickValues={list.map(({ dt_txt }) => new Date(dt_txt))}
							/>
						</VictoryChart>
						<VictoryChart
							theme={VictoryTheme.meteo}
							height={125}
							width={500}
							padding={{ top: 10, bottom: 25, left: 30, right: 30 }}
						>
							<VictoryScatter
								data={list}
								dataComponent={<ArrowPoint />}
								x={datum => new Date(datum.dt_txt)}
								y="wind.speed"
								domain={{ y: [0, 22] }}
								// labels={({ datum }) => {
								// 	console.log(datum.wind.deg);
								// 	return degToCompass(datum.wind.deg);
								// }}
							/>
							<VictoryAxis
								dependentAxis
								orientation="right"
								tickCount={2}
								tickFormat={t => `${t}m/s`}
								tickValues={[0, 20]}
								//offsetX={0}
								style={{
									axis: { stroke: "transparent" },
									tickLabels: { fontSize: 5, fill: colors.tacao }
								}}
							/>
						</VictoryChart>
					</ChartsContainer>
				) : null
			}
		</WeatherForecastConsumer>
	);
};
