import React, { useReducer } from "react";
import config from "./config";
import { AppProvider } from "./appContext";
import { WeatherProvider } from "./weatherContext";
import { WeatherForecastProvider } from "./weatherForecastContext";
import { ThemeProvider } from "styled-components";
import { useFetchWeather } from "./hooks/fetchWeather";
import { useFetchWeatherForecast } from "./hooks/fetchWeatherForecast";
import "./App.css";
import { GridContainer, GridArea } from "./components/grid";
import { WeatherIcon } from "./components/WeatherIcon";
import { WindIcon } from "./components/WindIcon";
import { PlaceName } from "./components/PlaceName";
import { TempIcon } from "./components/tempIcon";
import RainApp from "./RainApp";
import { Forecast } from "./components/Forecast";
//import { Alert } from "./components/Alert";
import { Time } from "./components/Time";
import { RefreshTimer } from "./components/RefreshTimer";

//const ThemeContext = React.createContext({});

function updateApp(state, action) {
	switch (action.type) {
		case "UPDATE_CITY":
			return { ...state, city: action.city };
		case "RELOAD_ENDPOINTS":
			return { ...state, timestamp: new Date() };
		default:
			throw new Error();
	}
}

function App() {
	const [state, dispatch] = useReducer(updateApp, config);
	const currentWeather = useFetchWeather({
		city: state.city,
		timestamp: state.timestamp
	});
	const weatherForecast = useFetchWeatherForecast({
		city: state.city,
		timestamp: state.timestamp
	});

	return (
		<ThemeProvider theme={{ mode: "light" }}>
			<AppProvider value={[state, dispatch]}>
				<RefreshTimer />
				<GridContainer>
					<WeatherProvider value={currentWeather}>
						<GridArea area="city" justify="left">
							<PlaceName />
							<Time />
						</GridArea>
						<GridArea area="icon">
							<WeatherIcon />
						</GridArea>
						<GridArea area="rain">
							{/* dit kan vast beter... */}
							{currentWeather &&
							currentWeather.weather &&
							currentWeather.weather.coord &&
							currentWeather.weather.coord.lat > 0 ? (
								<RainApp
									lat={currentWeather.weather.coord.lat}
									lon={currentWeather.weather.coord.lon}
								/>
							) : null}
						</GridArea>
						<GridArea area="wind">
							<TempIcon />
							<WindIcon />
						</GridArea>
						{/* <GridArea area="alert">
							<Alert />
						</GridArea> */}
					</WeatherProvider>
					<WeatherForecastProvider value={weatherForecast}>
						<GridArea area="graph" justify="stretch" align="center">
							<Forecast />
						</GridArea>
					</WeatherForecastProvider>
				</GridContainer>
			</AppProvider>
		</ThemeProvider>
	);
}

export default App;
