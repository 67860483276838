import { useEffect, useState } from "react";
import weatherModel from "./fetchWeather.model";

export const useFetchWeather = ({ city, timestamp }) => {
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [weather, setWeather] = useState(weatherModel);

	useEffect(() => {
		//const fetchData = async () => {
		const abortController = new AbortController();
		const signal = abortController.signal;

		setLoading(true);
		setError(null);

		/*await*/ fetch(
			`https://api.openweathermap.org/data/2.5/weather?q=${city},nl&lang=nl&units=metric&cnt=1&appid=2110f53ef617108d8b246d2cd0e2e22e`,
			{ signal: signal }
		)
			.then(res => res.json())
			.then(json => {
				if (json) {
					setWeather(json);
				} else {
					setWeather([]);
				}
				setLoading(false);
			})
			.catch(err => {
				setError(err);
				setLoading(false);
			});

		return function cleanup() {
			abortController.abort();
		};
		//};

		//const interval = setInterval(fetchData, 6000);

		//return () => clearInterval(interval);
	}, [city, timestamp]);

	//console.log("fetchWeather", weather, loading, error);
	return { weather, loading, error };
};
