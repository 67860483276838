import { assign } from "lodash";
import { colors as appColors } from "./colors.theme";

// *
// * Colors
// *
const colors = [
	appColors.seagull,
	appColors.wildRice,
	appColors.tacao,
	appColors.sulu
];

// *
// * Typography
// *
const sansSerif = "inherit";
const letterSpacing = "normal";
const fontSize = 8;
// *
// * Layout
// *
const baseProps = {
	width: 450,
	height: 300,
	padding: 50,
	colorScale: colors
};
// *
// * Labels
// *
const baseLabelStyles = {
	fontFamily: sansSerif,
	fontSize,
	letterSpacing,
	padding: 10,
	fill: appColors.hitGrey,
	stroke: "transparent"
};

const centeredLabelStyles = assign({ textAnchor: "middle" }, baseLabelStyles);
// *
// * Strokes
// *
const strokeLinecap = "round";
const strokeLinejoin = "round";

const meteoTheme = {
	area: assign(
		{
			style: {
				data: {
					fill: appColors.hitGrey
				},
				labels: baseLabelStyles
			}
		},
		baseProps
	),
	axis: assign(
		{
			style: {
				axis: {
					fill: "transparent",
					stroke: appColors.hitGrey,
					strokeWidth: 1,
					strokeLinecap,
					strokeLinejoin
				},
				axisLabel: assign({}, centeredLabelStyles, {
					padding: 25
				}),
				grid: {
					fill: "none",
					stroke: "none",
					pointerEvents: "painted"
				},
				ticks: {
					fill: "transparent",
					size: 1,
					stroke: "transparent"
				},
				tickLabels: baseLabelStyles
			}
		},
		baseProps
	),
	bar: assign(
		{
			style: {
				data: {
					fill: appColors.hitGrey,
					padding: 8,
					strokeWidth: 0
				},
				labels: baseLabelStyles
			}
		},
		baseProps
	),
	boxplot: assign(
		{
			style: {
				max: { padding: 8, stroke: appColors.hitGrey, strokeWidth: 1 },
				maxLabels: baseLabelStyles,
				median: {
					padding: 8,
					stroke: appColors.hitGrey,
					strokeWidth: 1
				},
				medianLabels: baseLabelStyles,
				min: { padding: 8, stroke: appColors.hitGrey, strokeWidth: 1 },
				minLabels: baseLabelStyles,
				q1: { padding: 8, fill: appColors.hitGrey },
				q1Labels: baseLabelStyles,
				q3: { padding: 8, fill: appColors.hitGrey },
				q3Labels: baseLabelStyles
			},
			boxWidth: 20
		},
		baseProps
	),
	candlestick: assign(
		{
			style: {
				data: {
					stroke: appColors.hitGrey,
					strokeWidth: 1
				},
				labels: baseLabelStyles
			},
			candleColors: {
				positive: "#ffffff",
				negative: appColors.hitGrey
			}
		},
		baseProps
	),
	chart: baseProps,
	errorbar: assign(
		{
			borderWidth: 8,
			style: {
				data: {
					fill: "transparent",
					stroke: appColors.hitGrey,
					strokeWidth: 2
				},
				labels: baseLabelStyles
			}
		},
		baseProps
	),
	group: assign(
		{
			colorScale: colors
		},
		baseProps
	),
	legend: {
		colorScale: colors,
		gutter: 10,
		orientation: "vertical",
		titleOrientation: "top",
		style: {
			data: {
				type: "circle"
			},
			labels: baseLabelStyles,
			title: assign({}, baseLabelStyles, { padding: 5 })
		}
	},
	line: assign(
		{
			style: {
				data: {
					fill: "transparent",
					stroke: appColors.hitGrey,
					strokeWidth: 2
				},
				labels: baseLabelStyles
			}
		},
		baseProps
	),
	pie: {
		style: {
			data: {
				padding: 10,
				stroke: "transparent",
				strokeWidth: 1
			},
			labels: assign({}, baseLabelStyles, { padding: 20 })
		},
		colorScale: colors,
		width: 400,
		height: 400,
		padding: 50
	},
	scatter: assign(
		{
			style: {
				data: {
					fill: appColors.hitGrey,
					stroke: "transparent",
					strokeWidth: 0
				},
				labels: baseLabelStyles
			}
		},
		baseProps
	),
	stack: assign(
		{
			colorScale: colors
		},
		baseProps
	),
	tooltip: {
		style: assign({}, baseLabelStyles, {
			padding: 5,
			pointerEvents: "none"
		}),
		flyoutStyle: {
			stroke: appColors.hitGrey,
			strokeWidth: 1,
			fill: "#f0f0f0",
			pointerEvents: "none"
		},
		cornerRadius: 5,
		pointerLength: 10
	},
	voronoi: assign(
		{
			style: {
				data: {
					fill: "transparent",
					stroke: "transparent",
					strokeWidth: 0
				},
				labels: assign({}, baseLabelStyles, {
					padding: 5,
					pointerEvents: "none"
				}),
				flyout: {
					stroke: appColors.hitGrey,
					strokeWidth: 1,
					fill: "#f0f0f0",
					pointerEvents: "none"
				}
			}
		},
		baseProps
	)
};

export default {
	meteo: meteoTheme
};
