import React from "react";
import styled from "styled-components";
import { iconPrimaryColor, iconSecondaryColor } from "../../colors.theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faCloudSleet,
	faCloudSnow,
	faCloudDownload
} from "@fortawesome/pro-duotone-svg-icons";

const conditionIcon = (conditionCode, cloudPercentage, isNight) => {
	if (conditionCode < 600 || conditionCode > 699) return faCloudDownload;

	let iconSlug = faCloudSnow;
	switch (conditionCode) {
		case 611:
		case 612:
		case 613:
		case 614:
		case 615:
		case 616:
			iconSlug = faCloudSleet;
			break;
		default:
			break;
	}

	return iconSlug;
};

export const Snow = styled(
	({ code, cloudPercentage, isNight, variant, ...rest }) => (
		<FontAwesomeIcon {...rest} />
	)
).attrs(({ code, cloudPercentage, isNight }) => ({
	icon: conditionIcon(code, cloudPercentage, isNight),
	variant: "snow"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;
