import React from 'react';

export const DuoToneGradient = ({id}) => (
    <svg aria-hidden="true" focusable="false" style={{width: 0, height: 0, position: 'absolute',}}>
      <linearGradient id={id} x2="0" y2="1">
        <stop offset="0%" stopColor="var(--color-stop-1)" />
        <stop offset="70%" stopColor="var(--color-stop-1)" />
        <stop offset="70%" stopColor="var(--color-stop-2)" />
        <stop offset="100%" stopColor="var(--color-stop-2)" />
      </linearGradient>
    </svg>
);
