import React from "react";
import styled from "styled-components";
import { iconPrimaryColor, iconSecondaryColor } from "../../colors.theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faWind,
	faWindWarning,
	faCompass,
	faWindsock
} from "@fortawesome/pro-duotone-svg-icons";

export const Wind = styled(({ speed, variant, ...rest }) => (
	<FontAwesomeIcon {...rest} />
)).attrs(({ speed }) => ({
	icon: speed > 12 ? faWindWarning : faWind,
	variant: speed > 12 ? "warning" : "clouds"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;

export const WindDirection = styled(({ deg, variant, ...rest }) => (
	<FontAwesomeIcon {...rest} />
)).attrs(({ deg }) => ({
	icon: faCompass,
	transform: { rotate: deg - 45 },
	variant: "compass"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;

export const WindSpeed = styled(({ variant, ...rest }) => (
	<FontAwesomeIcon {...rest} />
)).attrs(() => ({
	icon: faWindsock,
	variant: "compass"
}))`
	--fa-secondary-opacity: 1;
	--fa-primary-color: ${iconPrimaryColor};
	--fa-secondary-color: ${iconSecondaryColor};
`;
